import React, {useEffect, useMemo, useRef, useState} from "react";
import ReactDOMServer from 'react-dom/server';
import {Link, navigate} from "gatsby";
import PropTypes from "prop-types";
import {useLocation} from "@reach/router";

import NavigationLinkList from "../../components/NavigationLinkList";
import NavigationTopLinks from "../../components/NavigationTopLinks";
import useHeaderHeight from "../../hooks/useHeaderHeight";
import {fadeIn} from "../../helpers/fade";
import {getArticles, getLinkList, search} from "../../helpers/manualUtils";
import Accordion from "../../components/Accordion";
import IconSearch from "../../icons/IconSearch";
import IconPlay from "../../icons/IconPlay";
import IconMenuDots from "../../icons/IconMenuDots";
import {KeyEventHandler, Modal} from "../../components";
import NavigationButtons from "../../components/Manual/NavigationButtons";
import Key from "../../helpers/KeyCodes";

const useOnClickOutsidePredictiveSearch = (inputRef, predictiveSearchRef, handler) => {
    useEffect(() => {
        const listener = (event) => {
            if (!inputRef.current || inputRef.current.contains(event.target)) {
                return;
            }

            if (predictiveSearchRef.current && predictiveSearchRef.current.contains(event.target)) {
                return;
            }

            handler(event);
        };

        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [inputRef, predictiveSearchRef, handler]);
}

const Manual = ({manualCategory, topLinks}) => {
    const location = useLocation();
    const [inputValue, setInputValue] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [showPredictiveSearch, setShowPredictiveSearch] = useState(false);
    const [showSearchResult, setShowSearchResult] = useState(false);
    const [article, setArticle] = useState(null);
    const [showMobileSidebar, setShowMobileSidebar] = useState(false);
    const loading = useRef(false);
    const section = useRef();
    const content = useRef();
    const searchInputRef = useRef();
    const predictiveSearchRef = useRef();
    const [headerHeight,] = useHeaderHeight();
    useOnClickOutsidePredictiveSearch(searchInputRef, predictiveSearchRef, () => setShowPredictiveSearch(false));

    const articles = useMemo(() => {
        return getArticles(manualCategory);
    }, [manualCategory])

    const linklist = useMemo(() => getLinkList(manualCategory, article), [article]);

    const predictiveSearchResult = useMemo(() => {
        const trimmedInputValue = inputValue.trim();

        if (trimmedInputValue === "") {
            return [];
        }

        return search(manualCategory, trimmedInputValue);
    }, [inputValue, manualCategory]);

    const submittedSearchResult = useMemo(() => {
        const trimmedSearchQuery = searchQuery.trim();

        if (trimmedSearchQuery === "") {
            return [];
        }

        return search(manualCategory, trimmedSearchQuery)
    }, [searchQuery, manualCategory]);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        initializeSearchTerm(urlParams)
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const isUrlParamsHasQ = urlParams.has("query");

        updateSelectedArticle();

        if (location.hash) {
            if (isUrlParamsHasQ) {
                removeQueryParams("query");
            }

            resetSearch();
            return;
        }

        if (isUrlParamsHasQ) {
            const currentUrl = location.pathname + location.search;

            navigate(currentUrl, {replace: true})

            setShowSearchResult(true);
        }
    }, [location.hash]);

    useEffect(() => {
        scrollToArticle();
    }, [article]);

    const initializeSearchTerm = (urlParams) => {
        if (!urlParams.has("query")) return;

        const trimmedQueryParam = urlParams.get("query").trim();

        if (trimmedQueryParam === "") return;

        setInputValue(trimmedQueryParam);
        setSearchQuery(trimmedQueryParam);
        setShowSearchResult(true);
    }

    const updateSelectedArticle = () => {
        const newArticle = getNewArticle();

        if (!loading.current) {
            fadeIn(content.current, 400, () => (loading.current = false), () => (loading.current = false));
        }

        newArticle && setArticle(newArticle);
    };

    const getNewArticle = () => {
        let newArticle = articles && articles.get(location.hash.replace("#", ""));

        if (!newArticle) {
            const category = manualCategory[0];

            if (!category || !category.manualCategoryBlocks) return;

            if (articles) {
                const firstArticleKey = Array.from(articles.keys())[0];
                newArticle = articles.get(firstArticleKey);
            } else {
                newArticle = category.manualCategoryBlocks[0];
            }
        }

        return newArticle;
    }

    const removeQueryParams = (paramToRemove) => {
        const {origin, pathname, hash} = location;
        let urlParams = new URLSearchParams(location.search);

        urlParams.delete(paramToRemove);

        const urlParamsString = urlParams.toString();

        const newUrl = `${origin}${pathname}?${urlParamsString}${hash}`;

        navigate(newUrl, {replace: true});
    };

    const scrollToArticle = () => {
        if (!section || !section.current || !location.hash) return;

        setTimeout(() => {
            window.scrollTo({
                top: section.current.offsetTop - headerHeight
            });
            setShowMobileSidebar(false);
        }, 50);
    };

    const resetSearch = () => {
        setInputValue('');
        setSearchQuery('');
        setShowPredictiveSearch(false);
        setShowSearchResult(false);
    }

    const handleSearchInputChange = (event) => {
        !showPredictiveSearch && setShowPredictiveSearch(true);
        setInputValue(event.target.value);
    }

    const handleEnter = async () => {
        const trimmedInputValue = inputValue.trim();

        if (trimmedInputValue === "") return;

        const urlParams = new URLSearchParams(location.search);
        urlParams.set("query", trimmedInputValue);

        const url = `${location.pathname}?${urlParams.toString()}`

        await navigate(url, {replace: true});
        setSearchQuery(trimmedInputValue);
        setShowPredictiveSearch(false);
        setShowSearchResult(true);
    }

    const renderNotFoundMessage = () => {
        return (
            <>
                <p className={'manual__search-no-found-heading'}>
                    Not found.
                </p>

                <p className={'manual__search-no-found-text'}>
                    Try the name of the section or page you want to learn about.
                </p>
            </>
        )
    }

    const renderContent = () => {
        const listHeader = linklist
            .find(({links}) => links
                .find(({title}) => title === article?.blockTitle))
            ?.title;

        if (showSearchResult) {
            if (submittedSearchResult?.length) {
                return submittedSearchResult.map((article, index) => {

                    const accordionTitle = <span>{article.manualCategoryTitle} | <span
                        dangerouslySetInnerHTML={{__html: article.blockTitle}}/></span>;
                    const htmlString = article?.blockContent;

                    const youtubeRegex = /<a\s+(?:[^>]*?\s+)?href=["'](https?:\/\/www\.youtube\.com[^"']*)["'][^>]*>(.*?)<\/a>/gi;
                    const youtuDotBeRegex = /<a\s+(?:[^>]*?\s+)?href=["'](https?:\/\/youtu\.be[^"']*)["'][^>]*>(.*?)<\/a>/gi;

                    function replaceYoutubeLinks(match, href, content) {
                        const iconPlayHTML = ReactDOMServer.renderToStaticMarkup(<IconPlay />);
                        return `<a href="${href}" class="play" target="_blank" rel="noopener">${iconPlayHTML}${content}</a>`;
                    }

                    const modifiedHtmlString = htmlString
                        .replace(youtubeRegex, replaceYoutubeLinks)
                        .replace(youtuDotBeRegex, replaceYoutubeLinks);

                    return (
                        <Accordion
                            key={index}
                            title={accordionTitle}
                            content={modifiedHtmlString}
                            blockClass="manual"
                        />
                    );
                });
            }

            return renderNotFoundMessage();
        }
        const htmlString = article?.blockContent;

        const youtubeRegex = /<a\s+(?:[^>]*?\s+)?href=["'](https?:\/\/www\.youtube\.com[^"']*)["'][^>]*>(.*?)<\/a>/gi;
        const youtuDotBeRegex = /<a\s+(?:[^>]*?\s+)?href=["'](https?:\/\/youtu\.be[^"']*)["'][^>]*>(.*?)<\/a>/gi;

        function replaceYoutubeLinks(match, href, content) {
            const iconPlayHTML = ReactDOMServer.renderToStaticMarkup(<IconPlay />);
            return `<a href="${href}" class="play" target="_blank" rel="noopener">${iconPlayHTML}${content}</a>`;
        }

        const modifiedHtmlString = htmlString
            .replace(youtubeRegex, replaceYoutubeLinks)
            .replace(youtuDotBeRegex, replaceYoutubeLinks);

        return (
            <div>
                {listHeader
                    ? <h2 className="manual__list-title">{listHeader}</h2>
                    : null
                }

                <h3 className="medium-title manual__title">
                    {article?.blockTitle}
                </h3>

                <div
                    className="rte manual__content"
                    dangerouslySetInnerHTML={{__html: modifiedHtmlString}}
                />
            </div>
        );
    }

    const renderPredictiveResults = () => {
        const trimmedInputValue = inputValue.trim();

        if (trimmedInputValue === "" || !showPredictiveSearch) return;

        if (predictiveSearchResult?.length) {
            return (
                <ul className={'manual__form-predictive-results'} ref={predictiveSearchRef}>
                    {
                        predictiveSearchResult.map(article => {
                            const content = `<b>${article.manualCategoryTitle} | </b> ${article.predictiveSearchTitle}`

                            return (
                                <li className={'manual__form-predictive-results-item'} key={article.hash}>
                                    <Link
                                        className={'manual__form-predictive-results-item-link'}
                                        to={`#${article.hash}`}
                                        dangerouslySetInnerHTML={{__html: content}}
                                        aria-label={`Link to article "${article.blockTitle}" in category "${article.manualCategoryTitle}"`}
                                    />
                                </li>
                            )
                        })
                    }
                </ul>
            )
        }

        return (
            <div className={'manual__form-predictive-results manual__form-predictive-results--no-found'}>
                {renderNotFoundMessage()}
            </div>
        )
    }

    if (!article) {
        return null;
    }

    return (
        <section className="manual visible" ref={section}>
            <div className="manual__container">
                <div className={`manual__sidebar ${showMobileSidebar ? 'active' : ''}`}>
                    <NavigationTopLinks topLinks={topLinks} />

                    <div className="manual__navigation">
                        <NavigationLinkList linklist={linklist}/>
                    </div>
                </div>

                <div className="manual__main">
                    <form
                        className={'manual__form form'}
                        onSubmit={(event) => event.preventDefault()}
                    >
                        <label className={'manual__form-input-group'}>
                            <IconSearch/>

                            <KeyEventHandler
                                keyCode={[Key.Enter]}
                                event="keydown"
                                handler={handleEnter}
                                targetRef={searchInputRef}
                            >
                                <input
                                    type="text"
                                    className={"manual__form-input"}
                                    value={inputValue}
                                    onChange={handleSearchInputChange}
                                    onClick={() => setShowPredictiveSearch(true)}
                                    ref={searchInputRef}
                                    placeholder='Search...'
                                />
                            </KeyEventHandler>

                            {renderPredictiveResults()}
                        </label>
                    </form>

                    <button
                        className="manual__toggle-sidebar"
                        type="button"
                        onClick={() => setShowMobileSidebar(!showMobileSidebar)}
                    >
                        <IconMenuDots /> Sections
                    </button>

                    <Modal
                        isOpened={showMobileSidebar}
                        onClose={() => setShowMobileSidebar(false)}
                        isRounded={false}
                        isBordered={false}
                        isSpaced={false}
                        outerClassName="trap-focus--mobile-full"
                        className='manual__modal'
                        isCloseButtonShown={true}
                    >
                        <NavigationTopLinks topLinks={topLinks} />

                        <div className="manual__navigation">
                            <NavigationLinkList linklist={linklist}/>
                        </div>
                    </Modal>

                    <div className="manual__main-content" ref={content}>
                        {renderContent()}
                    </div>

                    <NavigationButtons linklist={linklist} />
                </div>
            </div>
        </section>
    );
};

Manual.propTypes = {
    manualCategory: PropTypes.array.isRequired
};

export default Manual;
