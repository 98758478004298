import React, { useState, useEffect, useCallback } from "react";

const useHash = () => {
    const [headerHeight, setHeaderHeight] = useState(0);

    const handler = useCallback(() => {
        const headerEl = document.getElementById("site-header");
        if (!headerEl) {
            return;
        }

        const headerHeight = +headerEl?.offsetHeight - +(headerEl && window.getComputedStyle(headerEl).getPropertyValue("--offset"));
        setHeaderHeight(headerHeight);
    }, []);

    useEffect(() => {
        handler();

        window.addEventListener('resize', handler);

        return () => {
            window.removeEventListener('resize', handler);
        };
    }, [])

    return [headerHeight, setHeaderHeight];
};

export default useHash;