import React, {useEffect, useState} from "react";
import {Link} from "gatsby";
import IconArrowLeft from "../../icons/IconArrowLeft";

const EXCLUDES_LINKS = [
    "Contact us",
];

const NavigationButtons = ({linklist}) => {
    const links = linklist.reduce((accumulator, currentValue) => {
        return accumulator.concat(currentValue.links);
    }, []);

    const [currentLink, setCurrentLink] = useState(null);
    const [previousLink, setPreviousLink] = useState(null);
    const [nextLink, setNextLink] = useState(null);

    useEffect(() => {
        if (links && links.length) {
            const currentLinkIndex = links.findIndex(({isActive}) => isActive) ?? 0;
            const currentLink = links[currentLinkIndex] ?? links[0];
            const previousLink = links[currentLinkIndex - 1] ?? null;
            const nextLink = links[currentLinkIndex + 1] ?? null;

            setCurrentLink(currentLink);
            setPreviousLink(previousLink);
            setNextLink(nextLink);
        }
    }, [links]);

    return (
        <>
            {currentLink && (previousLink || nextLink) && !EXCLUDES_LINKS.includes(currentLink.title)
                ? (
                    <div className="navigation-buttons">
                        {previousLink && (
                            <Link
                                to={previousLink.href}
                                aria-label={`Link to ${previousLink.title}`}
                                className="navigation-buttons__link navigation-buttons__link--prev"
                            >
                                <IconArrowLeft width="24" height="24" />

                                <div className="navigation-buttons__link-text">
                                    <span>Previous</span>
                                    {previousLink.title}
                                </div>
                            </Link>
                        )}

                        {nextLink && (
                            <Link
                                to={nextLink.href}
                                aria-label={`Link to ${nextLink.title}`}
                                className="navigation-buttons__link navigation-buttons__link--next"
                            >
                                <div className="navigation-buttons__link-text">
                                    <span>Next</span>
                                    {nextLink.title}
                                </div>

                                <IconArrowLeft width="24" height="24" />
                            </Link>
                        )}
                    </div>
                )
                : null
            }
        </>
    );
}

export default NavigationButtons;
