import React from "react";

const IconMenuDots = () => (
	<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M12.1934 5.8733C13.2832 5.8733 14.1667 4.98981 14.1667 3.89997C14.1667 2.81013 13.2832 1.92664 12.1934 1.92664C11.1035 1.92664 10.22 2.81013 10.22 3.89997C10.22 4.98981 11.1035 5.8733 12.1934 5.8733Z" fill="currentColor"/>
		<path d="M4.80665 5.8733C5.89649 5.8733 6.77998 4.98981 6.77998 3.89997C6.77998 2.81013 5.89649 1.92664 4.80665 1.92664C3.7168 1.92664 2.83331 2.81013 2.83331 3.89997C2.83331 4.98981 3.7168 5.8733 4.80665 5.8733Z" fill="currentColor"/>
		<path d="M12.1934 14.0733C13.2832 14.0733 14.1667 13.1898 14.1667 12.0999C14.1667 11.0101 13.2832 10.1266 12.1934 10.1266C11.1035 10.1266 10.22 11.0101 10.22 12.0999C10.22 13.1898 11.1035 14.0733 12.1934 14.0733Z" fill="currentColor"/>
		<path d="M4.80665 14.0733C5.89649 14.0733 6.77998 13.1898 6.77998 12.0999C6.77998 11.0101 5.89649 10.1266 4.80665 10.1266C3.7168 10.1266 2.83331 11.0101 2.83331 12.0999C2.83331 13.1898 3.7168 14.0733 4.80665 14.0733Z" fill="currentColor"/>
	</svg>
);

export default IconMenuDots;

