import React, {useState, useEffect, useCallback} from "react";
import {Link} from "gatsby";
import cn from "classnames";
import PropTypes from "prop-types";

import Accordion from "./Accordion";
import useMediaQuery from "../hooks/useMediaQuery";

const LinkList = ({linklist}) => {
    const [underlinedNested, setUnderlinedNested] = useState(-1);
    const [mobileActiveItem, setMobileActiveItem] = useState(null);
    const isExtraSmallBreakpoint = useMediaQuery("(max-width: 991px)");

    useEffect(() => {
        setUnderlinedNested(linklist.findIndex(list => list.isActive));
    }, [linklist]);

    const handleAccordionClick = useCallback((index) => {
        setMobileActiveItem(prevMobileActiveItem => index === prevMobileActiveItem ? null : index);
    }, []);

    if (!linklist.length) {
        return null;
    }

    return <>
        {linklist.map((list, index) => {
            if (list.links && list.links.length) {
                const isUnderlinedNested = index === underlinedNested;
                const isActiveAccordion = isExtraSmallBreakpoint && index === mobileActiveItem;

                const Links = (<LinkList linklist={list.links}/>);

                return (Links &&
                    <Accordion
                        key={index}
                        title={list.title}
                        content={Links}
                        blockClass="linklist"
                        useOpacity={!isExtraSmallBreakpoint}
                        initialActive={isActiveAccordion}
                        className={cn({'active': isUnderlinedNested})}
                        onOpenerClick={() => handleAccordionClick(index)}
                        chevronType='arrow'
                    />
                );
            }

            return (
                <Link
                    key={index}
                    to={list.href}
                    aria-label={`Link to ${list.title}`}
                    className={cn('linklist__link', {
                        'active': list.isActive
                    })}
                >
                    {list.title}
                </Link>
            );
        })}
    </>;
};

const NavigationLinkList = ({linklist}) => {
    if (!linklist.length) {
        return null;
    }

    return (
        <div className="linklist">
            <div className="linklist__wrapper">
                <LinkList
                    linklist={linklist}
                    className="linklist__container"
                />
            </div>
        </div>
    );
};

NavigationLinkList.propTypes = {
    linklist: PropTypes.array.isRequired
};

export default NavigationLinkList;
