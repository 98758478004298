import handleize from "../handleize";

const getCategoryHandle = (category, articleId) => {
    return handleize(`${category.manualCategoryTitle}-${category.manualCategoryBlocks[articleId]?.blockTitle}`);
};

export const getArticles = (manualCategory) => {
    return new Map(
        manualCategory.flatMap(category => {
            return category.manualCategoryBlocks.map((block, index) => {
                const hash = block.hash || getCategoryHandle(category, index);
                return [
                    hash,
                    {
                        ...block,
                        hash: hash,
                        category: category.manualCategoryTitle
                    }
                ];
            })
        })
    );
};

export const search = (manualCategory, searchTerm) => {
    const isBrowser = typeof window !== "undefined";
    const parser = isBrowser ? new DOMParser() : null;
    const queryLowerCase = searchTerm.toLowerCase();

    return manualCategory
        .flatMap((category) => category.manualCategoryBlocks
            .map((block) => {
                const titleLowerCase = block.blockTitle.toLowerCase();
                let contentTextLowerCase;

                if (isBrowser) {
                    const contentHTML = parser.parseFromString(block.blockContent, "text/html");
                    contentTextLowerCase = contentHTML.body.textContent.toLowerCase();
                } else {
                    contentTextLowerCase = block.blockContent.toLowerCase();
                }

                const isTitleMatch = titleLowerCase.includes(queryLowerCase);
                const isContentMatch = contentTextLowerCase.includes(queryLowerCase);

                return {
                    ...block,
                    isTitleMatch,
                    isContentMatch
                }
            })
            .filter((block) => block.isTitleMatch || block.isContentMatch)
            .map(block => ({
                ...block,
                predictiveSearchTitle: block.blockTitle.replace(
                    new RegExp(`(${searchTerm})`, 'gi'),
                    '<mark style="background-color: yellow;">$1</mark>'
                ),
                manualCategoryTitle: category.manualCategoryTitle
            })))
        .sort((a, b) => {
            if (a.isTitleMatch && !b.isTitleMatch) return -1;
            if (!a.isTitleMatch && b.isTitleMatch) return 1;
            return 0;
        });
};

export const getLinkList = (manualCategory, currentArticle) => {
    return manualCategory.map((category) => {
        const title = category.manualCategoryTitle;
        const links = category.manualCategoryBlocks.map((block, index) => {
            const articleTitle = block.blockTitle;
            const handle = block.hash || getCategoryHandle(category, index);
            const blockHash = `#${handle}`;

            return {
                title: articleTitle,
                links: null,
                href: blockHash,
                isActive: handle === currentArticle?.hash,
            }
        });

        const isActive = title === currentArticle?.category || links.some(link => link.isActive);

        return {
            title,
            links,
            href: null,
            isActive
        }
    });
};
