import React from "react";
import {Link} from "gatsby";
import IconStar from "../icons/IconStar";

const NavigationTopLinks = ({topLinks}) => {
    const {latestUpdates, paidServices} = topLinks;

    return (
        <>
            {paidServices.linkUrl?.uri || (latestUpdates.releaseNotesArticle && latestUpdates.releaseNotesArticle.uri)
                ? (
                    <div className="manual__top-links">
                        {paidServices.linkUrl?.uri
                            ? (
                                <Link
                                    className="manual__paid-services manual__top-links-button"
                                    to={paidServices.linkUrl?.uri}
                                >
                                    <IconStar />{paidServices.linkText || 'Paid Services'}
                                </Link>
                            )
                            : null
                        }

                        {latestUpdates.releaseNotesArticle && latestUpdates.releaseNotesArticle.uri
                            ? (
                                <Link
                                    className="manual__latest-updates manual__top-links-button"
                                    to={latestUpdates.releaseNotesArticle.uri}
                                >
                                    <span className="tag">New</span>
                                    {latestUpdates.linkText || 'Latest Updates'}
                                </Link>
                            )
                            : null
                        }
                    </div>
                )
                : null
            }
        </>
    )
}

export default NavigationTopLinks;
