import React from "react";

import DefaultLayout from "../layouts/DefaultLayout";
import ManualSection from "../sections/Manual/Manual";
import ProjectSummary from "../sections/Project/ProjectSummary";
import ProjectGallery from "../sections/Project/ProjectGallery";
import ProjectSecondaryBanner from "../sections/Project/ProjectSecondaryBanner";
import ProjectTextBlock from "../sections/Project/ProjectTextBlock";
import ProjectFullWidthBanner from "../sections/Project/ProjectFullWidthBanner";
import ProjectSmallTextBlocks from "../sections/Project/ProjectSmallTextBlocks";
import ProjectTextInfo from "../sections/Project/ProjectTextInfo";
import ProjectBanner from "../sections/Project/ProjectBanner";
import ProjectFeature from "../sections/Project/ProjectFeature";
import AppBanner from "../sections/App/AppBanner";
import Faqs from "../sections/Faqs";
import AppRichText from "../sections/App/AppRichText";
import AppFeatures from "../sections/App/AppFeatures"
import DefaultHelmet from "../seo/DefaultHelmet";
import beaconWidgetIds from "../settings/beacon-widget-ids";
import useBeaconWidget from "../hooks/useBeaconWidget";
import PageHeader from "../components/PageHeader";

const Manual = ({ pageContext }) => {
    const { manual } = pageContext;

    //TODO: DEV-13 1) Need check 'title' and 'projectTitle' props for sections
    //TODO: DEV-13 2) Need check duplicates sections
    const getSection = ({ fieldGroupName, ...section }, index) => {
        const key = index;

        switch (fieldGroupName) {
            case 'manual_Acfmanual_Sections_Manual': {
                return (
                    <ManualSection
                        topLinks={manual.acfManual.topLinks}
                        key={key}
                        {...section}
                    />
                );
            }

            case 'manual_Acfmanual_Sections_ThemeSummary': {
                return (
                    <ProjectSummary
                        key={key}
                        {...section}
                    />
                );
            }

            case 'manual_Acfmanual_Sections_ThemeGallery': {
                return (
                    <ProjectGallery
                        key={key}
                        projectTitle={"test"}
                        {...section}
                    />
                );
            }

            case 'manual_Acfmanual_Sections_ThemeSecondaryBanner': {
                return (
                    <ProjectSecondaryBanner
                        key={key}
                        projectTitle={"test"}
                        {...section}
                    />
                );
            }

            case 'manual_Acfmanual_Sections_ThemeSingleTextBlock': {
                return (
                    <ProjectTextBlock
                        key={key}
                        {...section}
                    />
                );
            }

            case 'manual_Acfmanual_Sections_ThemeFullWidthBanner': {
                return (
                    <ProjectFullWidthBanner
                        key={key}
                        {...section}
                    />
                );
            }

            case 'manual_Acfmanual_Sections_ThemeSmallTextBlocks': {
                return (
                    <ProjectSmallTextBlocks
                        key={key}
                        {...section}
                    />
                );
            }

            case 'manual_Acfmanual_Sections_ThemeTextInfo': {
                return (
                    <ProjectTextInfo
                        key={key}
                        {...section}
                    />
                );
            }

            case 'manual_Acfmanual_Sections_ThemeBanner': {
                return (
                    <ProjectBanner
                        key={key}
                        projectTitle={"test"}
                        {...section}
                    />
                );
            }

            case 'manual_AcfshopifyTheme_Sections_ThemeFeature': {
                return (
                    <ProjectFeature
                        key={key}
                        projectTitle={"test"}
                        {...section}
                    />
                );
            }

            case 'manual_Acfmanual_Sections_AppBanner': {
                return (
                    <AppBanner
                        key={key}
                        title={"test"}
                        {...section}
                    />
                );
            }

            case 'manual_Acfmanual_Sections_AppSummary': {
                return (
                    <ProjectSummary
                        key={key}
                        className="project-summary--secondary"
                        {...section}
                    />
                );
            }

            case 'manual_Acfmanual_Sections_AppFaqs': {
                return (
                    <Faqs
                        key={key}
                        {...section}
                    />
                );
            }

            case 'manual_Acfmanual_Sections_AppGallery': {
                return (
                    <ProjectGallery
                        key={key}
                        projectTitle={"test"}
                        {...section}
                    />
                );
            }

            case 'manual_Acfmanual_Sections_AppRichText': {
                return (
                    <AppRichText
                        key={key}
                        {...section}
                    />
                )
            }

            case 'manual_Acfmanual_Sections_AppFeatures': {
                return (
                    <AppFeatures
                        key={key}
                        {...section}
                    />
                );
            }

            default: {
                return null;
            }
        }
    };

    const getSections = () => {
        if(!Array.isArray(manual.acfManual?.sections)) {
            return null;
        }

        return manual.acfManual.sections.map(getSection);
    }

    const pageTitle = manual?.acfManual?.metaData?.title || "Manual";
    const pageDescription = manual?.acfManual?.metaData?.description || "Manual description";

    const beaconId = manual?.acfManual?.postType?.uri?.startsWith('/apps') ? beaconWidgetIds.APP : beaconWidgetIds.THEME;

    useBeaconWidget(beaconId);

    return (
        <DefaultLayout isHeaderWithBlackText={true}>
            <DefaultHelmet title={pageTitle} description={pageDescription}/>

            {manual.page_header && manual.page_header.title && <PageHeader {...manual.page_header} />}

            <div className="manual-wrapper">
                {getSections()}
            </div>
        </DefaultLayout>
    )
};

export default Manual;
