import React from 'react';

const IconSearch = () => {
    return (
        <svg
            fill="none"
            viewBox="0 0 16 16"
            aria-hidden="true"
        >
            <path d="m12.523 11.463 3.2122 3.2115-1.0612 1.0612-3.2115-3.2122c-1.195 0.9579-2.6813 1.4789-4.2128 1.4767-3.726 0-6.75-3.024-6.75-6.75s3.024-6.75 6.75-6.75 6.75 3.024 6.75 6.75c0.0022 1.5315-0.5188 3.0178-1.4767 4.2128zm-1.5045-0.5565c0.9518-0.97889 1.4834-2.291 1.4812-3.6563 0-2.9002-2.3497-5.25-5.25-5.25-2.9002 0-5.25 2.3498-5.25 5.25 0 2.9003 2.3498 5.25 5.25 5.25 1.3653 0.0022 2.6774-0.5294 3.6563-1.4812l0.1125-0.1125z" fill="#BCBCBC"/>
        </svg>

    );
};

export default IconSearch;
